.custom-tooltip {
  background-color: #ffffff; /* Warna latar belakang */
  border: 1px solid #000000; /* Garis pinggir tooltip */
  padding: 10px; /* Jarak antara isi tooltip dengan batas kotak */
  border-radius: 10px; /* Membuat sudut kotak lebih melengkung */
  font-family: Arial, sans-serif; /* Ganti dengan font yang Anda inginkan */
  font-size: 12px; /* Ukuran font tooltip */
  border-radius: 6px;
}

.img-tooltip {
  width: 30px; /* Warna latar belakang */
  height: 30px;
}
